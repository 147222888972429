define("ln-ember-form-elements/templates/components/form-elements/upload-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "PxqX0wBX",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[9,\"input\",true],[13,\"onchange\",[31,18,6,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[]],\"upload\"],null],null],[13,\"disabled\",[27,[24,0],[\"disabled\"]],null],[13,\"multiple\",[27,[24,0],[\"multiple\"]],null],[12,\"type\",\"file\",null],[10],[11],[1,1,0,0,\"\\n\"],[16,1,null]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "meta": {
      "moduleName": "ln-ember-form-elements/templates/components/form-elements/upload-button.hbs"
    }
  });
});