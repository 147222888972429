define("ln-ember-contact-menu/templates/components/micro-menu-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "XY3GnZ4U",
    "block": "{\"symbols\":[\"@isFake\"],\"statements\":[[9,\"a\",true],[13,\"href\",[27,[24,0],[\"href\"]],null],[13,\"target\",[27,[24,0],[\"target\"]],null],[10],[1,1,0,0,\"\\n\"],[5,[27,[26,2,\"BlockHead\"],[]],[[27,[24,1],[]]],null,[[\"default\",\"else\"],[{\"statements\":[[1,1,0,0,\"    \"],[9,\"i\",true],[13,\"class\",[32,[\"micro-menu--icon \",[31,98,2,[27,[26,2,\"CallHead\"],[]],[[27,[24,0],[\"hasIcon\"]],\"fake-icon\"],null]]],null],[10],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[1,1,0,0,\"    \"],[9,\"i\",true],[13,\"class\",[32,[\"micro-menu--icon \",[27,[26,1,\"AppendSingleId\"],[]],\"-icon\"]],null],[10],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]],[1,1,0,0,\"\\n  \"],[9,\"section\",true],[10],[1,1,0,0,\"\\n    \"],[9,\"h4\",true],[12,\"class\",\"micro-menu-item--title\",null],[13,\"title\",[27,[24,0],[\"titleTooltip\"]],null],[10],[1,1,0,0,\"\\n      \"],[5,[27,[26,0,\"BlockHead\"],[]],[[27,[24,1],[]]],null,[[\"default\"],[{\"statements\":[[1,0,0,0,[27,[24,0],[\"title\"]]]],\"parameters\":[]}]]],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n\\n\"],[5,[27,[26,2,\"BlockHead\"],[]],[[27,[24,0],[\"description\"]]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"      \"],[9,\"p\",true],[12,\"class\",\"micro-menu-item--description\",null],[13,\"title\",[27,[24,0],[\"descriptionTooltip\"]],null],[10],[1,1,0,0,\"\\n        \"],[5,[27,[26,0,\"BlockHead\"],[]],[[27,[24,1],[]]],null,[[\"default\"],[{\"statements\":[[1,0,0,0,[27,[24,0],[\"description\"]]]],\"parameters\":[]}]]],[1,1,0,0,\"\\n      \"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]],[1,1,0,0,\"  \"],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"unless\",\"name\",\"if\"]}",
    "meta": {
      "moduleName": "ln-ember-contact-menu/templates/components/micro-menu-item.hbs"
    }
  });
});