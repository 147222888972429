define("ln-ember-form-elements/templates/components/form-elements/dropdown-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "W4obnd10",
    "block": "{\"symbols\":[\"@loading\",\"&default\"],\"statements\":[[16,2,[[31,9,4,[27,[26,2,\"CallHead\"],[]],null,[[\"button\",\"dropdown\"],[[31,24,9,[27,[26,0,\"CallHead\"],[]],[\"form-elements/dropdown-button/button\"],[[\"toggleDropdown\",\"isOpen\",\"loading\"],[[31,93,6,[27,[26,1,\"CallHead\"],[]],[[27,[24,0],[]],\"toggleDropdown\"],null],[27,[24,0],[\"dropdownOpen\"]],[27,[24,1],[]]]]],[31,184,9,[27,[26,0,\"CallHead\"],[]],[\"form-elements/dropdown-button/dropdown\"],[[\"isOpen\"],[[27,[24,0],[\"dropdownOpen\"]]]]]]]]]],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"action\",\"hash\"]}",
    "meta": {
      "moduleName": "ln-ember-form-elements/templates/components/form-elements/dropdown-button.hbs"
    }
  });
});