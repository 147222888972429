define("ln-ember-form-elements/templates/components/form-elements/radio-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "EGLbTI8J",
    "block": "{\"symbols\":[\"@name\",\"@label\",\"&default\"],\"statements\":[[9,\"input\",true],[13,\"name\",[27,[24,1],[]],null],[13,\"value\",[27,[24,0],[\"buttonValue\"]],null],[13,\"checked\",[27,[24,0],[\"checked\"]],null],[12,\"type\",\"radio\",null],[10],[11],[1,1,0,0,\"\\n\"],[9,\"span\",true],[13,\"class\",[32,[\"radio-circle \",[31,116,2,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[\"checked\"]],\"selected\"],null]]],null],[10],[11],[1,1,0,0,\"\\n\"],[9,\"span\",true],[12,\"class\",\"radio-label\",null],[10],[1,0,0,0,[27,[24,2],[]]],[11],[1,1,0,0,\"\\n\"],[16,3,null]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "ln-ember-form-elements/templates/components/form-elements/radio-button.hbs"
    }
  });
});