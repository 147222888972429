define("ln-ember-contact-menu/templates/components/micro-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "qxeIlPAd",
    "block": "{\"symbols\":[],\"statements\":[[5,[27,[26,1,\"BlockHead\"],[]],[[27,[24,0],[\"microMenu\",\"isActive\"]]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"  \"],[9,\"div\",true],[12,\"class\",\"micro-menu--overlay\",null],[12,\"tabindex\",\"0\",null],[13,\"onclick\",[27,[24,0],[\"_close\"]],null],[10],[11],[1,1,0,0,\"\\n\\n\"],[5,[27,[26,3,\"BlockHead\"],[]],null,[[\"class\",\"isShown\",\"hideOn\",\"popperTarget\",\"onChange\",\"popperContainer\"],[[31,143,6,[27,[26,2,\"CallHead\"],[]],[\"micro-menu--container\",[31,175,2,[27,[26,1,\"CallHead\"],[]],[[27,[24,0],[\"isNorthern\"]],\" is-northern\",\"\"],null],[31,214,2,[27,[26,1,\"CallHead\"],[]],[[27,[24,0],[\"isRendered\"]],\" is-rendered\",\"\"],null]],null],true,\"escapekey\",[27,[24,0],[\"targetElement\"]],[27,[24,0],[\"_onChangePopoverVisibility\"]],[27,[24,0],[\"element\"]]]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"    \"],[1,0,0,0,[31,418,9,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[\"component\"]]],[[\"didActivateItem\"],[[27,[24,0],[\"_didActivateItem\"]]]]]],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"component\",\"if\",\"concat\",\"attach-popover\"]}",
    "meta": {
      "moduleName": "ln-ember-contact-menu/templates/components/micro-menu.hbs"
    }
  });
});