define("ln-ember-toolkit/templates/components/ln-tooltip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "u5XuAHqo",
    "block": "{\"symbols\":[\"@content\",\"&default\",\"@isShown\",\"@showOn\"],\"statements\":[[7,\"attach-tooltip\",[],[[\"@class\",\"@isShown\",\"@showOn\",\"@hideOn\"],[\"LnTooltip\",[27,[24,3],[]],[27,[24,4],[]],[27,[26,0,\"AppendSingleId\"],[]]]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"\\n\"],[5,[27,[26,1,\"BlockHead\"],[]],[[28,[24,2]]],null,[[\"default\",\"else\"],[{\"statements\":[[1,1,0,0,\"    \"],[16,2,null],[1,1,0,0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[1,1,0,0,\"    \"],[1,0,0,0,[27,[24,1],[]]],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"hideOn\",\"if\"]}",
    "meta": {
      "moduleName": "ln-ember-toolkit/templates/components/ln-tooltip.hbs"
    }
  });
});