define("ln-ember-form-elements/templates/components/form-elements/option-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "ag4CF0U5",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[5,[27,[26,1,\"BlockHead\"],[]],[[27,[24,0],[\"label\"]]],null,[[\"default\"],[{\"statements\":[[9,\"label\",true],[13,\"for\",[27,[24,0],[\"elementId\"]],null],[10],[1,0,0,0,[27,[24,0],[\"label\"]]],[11]],\"parameters\":[]}]]],[1,1,0,0,\"\\n\\n\"],[5,[27,[26,4,\"BlockHead\"],[]],[[31,0,0,[27,[26,3,\"CallHead\"],[]],[[31,0,0,[27,[26,3,\"CallHead\"],[]],[[27,[24,0],[\"renderOptions\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[5,[27,[26,2,\"BlockHead\"],[]],null,[[\"theme\",\"action\"],[[31,160,2,[27,[26,1,\"CallHead\"],[]],[[27,[24,1],[\"active\"]],[27,[24,0],[\"highlightTheme\"]],[27,[24,0],[\"theme\"]]],null],[31,217,6,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[]],\"setValue\",[27,[24,1],[]]],null]]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"    \"],[1,0,0,0,[27,[24,1],[\"label\"]]],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"if\",\"form-elements/styled-button\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "ln-ember-form-elements/templates/components/form-elements/option-button.hbs"
    }
  });
});