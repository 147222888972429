define("ln-ember-form-elements/templates/components/form-elements/validation-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "epuwBAY8",
    "block": "{\"symbols\":[\"scope\",\"@icon\",\"&attrs\",\"@errorMessage\",\"@warningMessage\"],\"statements\":[[5,[27,[26,3,\"BlockHead\"],[]],[\"FormElementsValidationIcon\"],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"  \"],[7,\"svg-icon\",[[14,\"class\",[27,[24,1],[]],null],[14,\"id\",[27,[24,0],[\"_elementId\"]],null],[15,3]],[[\"@name\"],[[31,68,2,[27,[26,0,\"CallHead\"],[]],[\"exclamation\",[27,[24,2],[]]],null]]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"\\n    \"],[7,\"ln-tooltip\",[],[[\"@arrow\",\"@id\"],[true,[32,[[27,[24,0],[\"_elementId\"]],\"-validationTooltip\"]]]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"\\n      \"],[9,\"span\",true],[13,\"class\",[32,[[27,[24,1],[]],\"-validationMessage\\n        \",[31,280,2,[27,[26,2,\"CallHead\"],[]],[[27,[24,4],[]],[31,308,6,[27,[26,1,\"CallHead\"],[]],[[27,[24,1],[]],\"-validationMessage--error\"],null],[31,361,6,[27,[26,1,\"CallHead\"],[]],[[27,[24,1],[]],\"-validationMessage--warning\"],null]],null],\"\\n      \"]],null],[10],[1,1,0,0,\"\\n        \"],[1,0,0,0,[31,435,2,[27,[26,0,\"CallHead\"],[]],[[27,[24,4],[]],[27,[24,5],[]]],null]],[1,1,0,0,\"\\n      \"],[11],[1,1,0,0,\"\\n    \"]],\"parameters\":[]}]]],[1,1,0,0,\"\\n  \"]],\"parameters\":[]}]]],[1,1,0,0,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"or\",\"concat\",\"if\",\"let\"]}",
    "meta": {
      "moduleName": "ln-ember-form-elements/templates/components/form-elements/validation-icon.hbs"
    }
  });
});