define("ln-ember-form-elements/templates/components/form-elements/number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "q3L7zAqD",
    "block": "{\"symbols\":[\"scope\",\"MaskedInput\",\"@appendElement\",\"@autocomplete\",\"@autoFocus\",\"@label\",\"@disabled\",\"@error\",\"@maxLength\",\"@name\",\"@prependElement\",\"@readonly\",\"@onFocusIn\",\"@onFocusOut\",\"@onKeyDown\",\"@onKeyPress\",\"@onKeyUp\",\"@title\",\"@warning\",\"@class\",\"&attrs\"],\"statements\":[[5,[27,[26,1,\"BlockHead\"],[]],[\"FormNumber\",[31,23,9,[27,[26,0,\"CallHead\"],[]],[\"form-elements/-masked-input\"],null]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"  \"],[7,[27,[24,2],[]],[[14,\"class\",[32,[[27,[24,1],[]],\" \",[27,[24,20],[]]]],null],[15,21]],[[\"@appendElement\",\"@autocomplete\",\"@autofocus\",\"@label\",\"@disabled\",\"@error\",\"@maxlength\",\"@name\",\"@placeholder\",\"@prependElement\",\"@readonly\",\"@value\",\"@options\",\"@onAccept\",\"@onFocusIn\",\"@onFocusOut\",\"@onKeyDown\",\"@onKeyPress\",\"@onKeyUp\",\"@onCopy\",\"@title\",\"@warning\"],[[27,[24,3],[]],[27,[24,4],[]],[27,[24,5],[]],[27,[24,6],[]],[27,[24,7],[]],[27,[24,8],[]],[27,[24,9],[]],[27,[24,10],[]],[27,[24,0],[\"_placeholder\"]],[27,[24,11],[]],[27,[24,12],[]],[27,[24,0],[\"_value\"]],[27,[24,0],[\"maskOptions\"]],[27,[24,0],[\"_onAccept\"]],[27,[24,13],[]],[27,[24,14],[]],[27,[24,15],[]],[27,[24,16],[]],[27,[24,17],[]],[27,[24,0],[\"_onCopy\"]],[27,[24,18],[]],[27,[24,19],[]]]],null],[1,1,0,0,\"\\n\"]],\"parameters\":[1,2]}]]]],\"hasEval\":false,\"upvars\":[\"component\",\"let\"]}",
    "meta": {
      "moduleName": "ln-ember-form-elements/templates/components/form-elements/number.hbs"
    }
  });
});