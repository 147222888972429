define("ember-collection/utils/style-generators", ["exports", "ember-collection/utils/translate"], function (_exports, _translate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatPercentageStyle = formatPercentageStyle;
  _exports.formatPixelStyle = formatPixelStyle;
  function formatPixelStyle(pos, width, height) {
    var css = 'position:absolute;top:0;left:0;';
    css += (0, _translate.translateCSS)(pos.x, pos.y);
    css += 'width:' + width + 'px;height:' + height + 'px;';
    return css;
  }
  function formatPercentageStyle(pos, width, height) {
    var css = 'position:absolute;top:0;left:0;';
    css += (0, _translate.translateCSS)(pos.x, pos.y);
    css += 'width:' + width + '%;height:' + height + 'px;';
    return css;
  }
});