define("ln-ember-form-elements/templates/components/flash-messages/flash-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "VHAkSD9S",
    "block": "{\"symbols\":[],\"statements\":[[9,\"p\",true],[10],[1,0,0,0,[27,[24,0],[\"message\",\"text\"]]],[11],[1,1,0,0,\"\\n\\n\"],[9,\"button\",true],[12,\"class\",\"close-message\",null],[13,\"onclick\",[31,84,6,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[]],\"removeMessage\"],null],null],[12,\"type\",\"button\",null],[10],[1,1,0,0,\"\\n  \"],[1,0,0,0,[31,114,8,[27,[26,1,\"CallHead\"],[]],[\"exit\"],[[\"class\"],[\"Button-icon\"]]]],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"svg-icon\"]}",
    "meta": {
      "moduleName": "ln-ember-form-elements/templates/components/flash-messages/flash-message.hbs"
    }
  });
});