define("ln-ember-form-elements/templates/components/form-elements/image-upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "/OMjzQYP",
    "block": "{\"symbols\":[\"file\"],\"statements\":[[5,[27,[26,0,\"BlockHead\"],[]],[[27,[24,0],[\"label\"]]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"  \"],[9,\"div\",true],[12,\"class\",\"label\",null],[10],[1,0,0,0,[27,[24,0],[\"label\"]]],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]],[1,1,0,0,\"\\n\"],[9,\"div\",true],[12,\"class\",\"scroll-content\",null],[10],[1,1,0,0,\"\\n  \"],[9,\"input\",true],[12,\"class\",\"file-input\",null],[12,\"title\",\"\",null],[12,\"accept\",\"image/*\",null],[12,\"multiple\",\"\",null],[12,\"type\",\"file\",null],[10],[11],[1,1,0,0,\"\\n\\n\"],[5,[27,[26,0,\"BlockHead\"],[]],[[27,[24,0],[\"fileObjects\",\"length\"]]],null,[[\"default\"],[{\"statements\":[[5,[27,[26,4,\"BlockHead\"],[]],[[31,0,0,[27,[26,3,\"CallHead\"],[]],[[31,0,0,[27,[26,3,\"CallHead\"],[]],[[27,[24,0],[\"fileObjects\"]]],null]],null]],[[\"key\"],[\"id\"]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"      \"],[9,\"div\",true],[13,\"data-id\",[27,[24,1],[\"id\"]],null],[13,\"class\",[32,[\"preview-image \",[31,382,2,[27,[26,0,\"CallHead\"],[]],[[27,[24,1],[\"isRemoved\"]],\"is-removed\"],null],\" \",[31,417,2,[27,[26,0,\"CallHead\"],[]],[[27,[24,1],[\"isFile\"]],\"is-file\"],null]]],null],[13,\"style\",[27,[24,1],[\"style\"]],null],[10],[1,1,0,0,\"\\n\"],[1,1,0,0,\"        \"],[1,0,0,0,[31,545,27,[27,[26,2,\"CallHead\"],[]],null,[[\"action\",\"icon\",\"theme\"],[[31,591,6,[27,[26,1,\"CallHead\"],[]],[[27,[24,0],[]],\"removeFile\",[27,[24,1],[]]],null],\"close\",\"dark\"]]]],[1,1,0,0,\"\\n      \"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[1]}]]],[1,1,0,0,\"\\n    \"],[9,\"div\",true],[12,\"class\",\"dummy\",null],[13,\"onclick\",[31,735,6,[27,[26,1,\"CallHead\"],[]],[[27,[24,0],[]],\"clickDummy\"],null],null],[12,\"role\",\"button\",null],[10],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"action\",\"form-elements/styled-button\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "ln-ember-form-elements/templates/components/form-elements/image-upload.hbs"
    }
  });
});