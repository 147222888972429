define("ln-ember-form-elements/templates/components/form-elements/toggle-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "kzQCHH/J",
    "block": "{\"symbols\":[],\"statements\":[[9,\"input\",true],[13,\"id\",[27,[24,0],[\"checkboxId\"]],null],[13,\"checked\",[27,[24,0],[\"value\"]],null],[13,\"onchange\",[31,80,6,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[]],\"toggleValue\"],null],null],[12,\"type\",\"checkbox\",null],[10],[11],[1,1,0,0,\"\\n\"],[9,\"label\",true],[13,\"for\",[27,[24,0],[\"checkboxId\"]],null],[10],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "meta": {
      "moduleName": "ln-ember-form-elements/templates/components/form-elements/toggle-button.hbs"
    }
  });
});