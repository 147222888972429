define("ln-ember-form-elements/templates/components/flash-messages/flash-messages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "jHmNilLQ",
    "block": "{\"symbols\":[\"message\"],\"statements\":[[5,[27,[26,3,\"BlockHead\"],[]],[[31,0,0,[27,[26,2,\"CallHead\"],[]],[[31,0,0,[27,[26,2,\"CallHead\"],[]],[[27,[24,0],[\"visibleMessages\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"  \"],[1,0,0,0,[31,48,28,[27,[26,1,\"CallHead\"],[]],null,[[\"message\",\"allMessages\",\"onHeightChange\",\"onRemove\",\"onCloseClick\"],[[27,[24,1],[]],[27,[24,0],[\"visibleMessages\"]],[31,154,6,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[]],\"setMessageHeight\",[27,[24,1],[]]],null],[31,203,6,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[]],\"removeMessageHeight\",[27,[24,1],[]]],null],[31,259,6,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[]],\"removeMessage\",[27,[24,1],[]]],null]]]]],[1,1,0,0,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"flash-messages/flash-message\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "ln-ember-form-elements/templates/components/flash-messages/flash-messages.hbs"
    }
  });
});