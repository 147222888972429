define("ln-ember-form-elements/templates/components/form-elements/styled-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "01XPew3h",
    "block": "{\"symbols\":[],\"statements\":[[1,0,0,0,[31,2,5,[27,[26,0,\"CallHead\"],[]],null,[[\"type\",\"checked\",\"id\",\"disabled\"],[\"checkbox\",[27,[24,0],[\"inputValue\"]],[27,[24,0],[\"checkboxId\"]],[27,[24,0],[\"readonlyAttribute\"]]]]]],[1,1,0,0,\"\\n\"],[9,\"label\",true],[13,\"for\",[27,[24,0],[\"checkboxId\"]],null],[10],[1,0,0,0,[27,[24,0],[\"label\"]]],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"input\"]}",
    "meta": {
      "moduleName": "ln-ember-form-elements/templates/components/form-elements/styled-checkbox.hbs"
    }
  });
});