define("ln-ember-form-elements/templates/components/form-elements/percent", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "1LwwsRsg",
    "block": "{\"symbols\":[\"scope\",\"NumberInput\",\"@appendElement\",\"@autocomplete\",\"@autoFocus\",\"@disabled\",\"@error\",\"@label\",\"@max\",\"@maxLength\",\"@min\",\"@name\",\"@onAccept\",\"@onFocusIn\",\"@onFocusOut\",\"@onKeyDown\",\"@onKeyPress\",\"@onKeyUp\",\"@padFractionalZeros\",\"@placeholder\",\"@prependElement\",\"@readonly\",\"@shouldRound\",\"@title\",\"@value\",\"@warning\",\"&attrs\",\"@class\"],\"statements\":[[5,[27,[26,1,\"BlockHead\"],[]],[\"FormPercent\",[31,24,9,[27,[26,0,\"CallHead\"],[]],[\"form-elements/number\"],null]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"  \"],[7,[27,[24,2],[]],[[15,27],[14,\"class\",[32,[[27,[24,28],[]],\" \",[27,[24,1],[]]]],null]],[[\"@appendElement\",\"@autocomplete\",\"@autofocus\",\"@deserialize\",\"@disabled\",\"@error\",\"@inputScale\",\"@label\",\"@max\",\"@maxlength\",\"@min\",\"@name\",\"@normalizeZeros\",\"@onAccept\",\"@onFocusIn\",\"@onFocusOut\",\"@onKeyDown\",\"@onKeyPress\",\"@onKeyUp\",\"@padFractionalZeros\",\"@placeholder\",\"@placeUnitBefore\",\"@prependElement\",\"@readonly\",\"@scale\",\"@serialize\",\"@shouldRound\",\"@title\",\"@unit\",\"@unitSeparator\",\"@value\",\"@warning\"],[[27,[24,3],[]],[27,[24,4],[]],[27,[24,5],[]],[27,[24,0],[\"_deserialize\"]],[27,[24,6],[]],[27,[24,7],[]],[27,[24,0],[\"_inputScale\"]],[27,[24,8],[]],[27,[24,9],[]],[27,[24,10],[]],[27,[24,11],[]],[27,[24,12],[]],false,[27,[24,13],[]],[27,[24,14],[]],[27,[24,15],[]],[27,[24,16],[]],[27,[24,17],[]],[27,[24,18],[]],[27,[24,19],[]],[27,[24,20],[]],false,[27,[24,21],[]],[27,[24,22],[]],[27,[24,0],[\"_scale\"]],[27,[24,0],[\"_serialize\"]],[27,[24,23],[]],[27,[24,24],[]],[27,[24,0],[\"_unit\"]],[27,[24,0],[\"_unitSeparator\"]],[27,[24,25],[]],[27,[24,26],[]]]],null],[1,1,0,0,\"\\n\"]],\"parameters\":[1,2]}]]]],\"hasEval\":false,\"upvars\":[\"component\",\"let\"]}",
    "meta": {
      "moduleName": "ln-ember-form-elements/templates/components/form-elements/percent.hbs"
    }
  });
});