define("ln-ember-form-elements/templates/components/form-elements/expanding-textarea", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "GQigYHat",
    "block": "{\"symbols\":[\"@label\",\"@maxLength\",\"@value\",\"@readonly\",\"@placeholder\"],\"statements\":[[5,[27,[26,0,\"BlockHead\"],[]],[[27,[24,1],[]]],null,[[\"default\"],[{\"statements\":[[9,\"label\",true],[13,\"for\",[32,[[27,[24,0],[\"elementId\"]],\"-textarea\"]],null],[10],[1,0,0,0,[27,[24,1],[]]],[11]],\"parameters\":[]}]]],[1,1,0,0,\"\\n\\n\"],[9,\"div\",true],[12,\"class\",\"textarea-wrapper\",null],[10],[1,1,0,0,\"\\n  \"],[1,0,0,0,[31,117,8,[27,[26,3,\"CallHead\"],[]],null,[[\"id\",\"placeholder\",\"readonly\",\"value\",\"maxlength\",\"focus-in\",\"focus-out\",\"key-up\"],[[31,134,6,[27,[26,2,\"CallHead\"],[]],[[27,[24,0],[\"elementId\"]],\"-textarea\"],null],[27,[24,5],[]],[27,[24,4],[]],[27,[24,3],[]],[27,[24,2],[]],[31,277,6,[27,[26,1,\"CallHead\"],[]],[[27,[24,0],[]],\"focusIn\"],null],[31,310,6,[27,[26,1,\"CallHead\"],[]],[[27,[24,0],[]],\"focusOut\"],null],[31,341,6,[27,[26,1,\"CallHead\"],[]],[[27,[24,0],[]],\"keyUp\"],null]]]]],[1,1,0,0,\"\\n\\n\"],[5,[27,[26,0,\"BlockHead\"],[]],[[27,[24,2],[]]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"    \"],[9,\"span\",true],[13,\"class\",[32,[\"characters-countdown \",[31,424,2,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[\"limitExceeded\"]],\"limit-exceeded\"],null]]],null],[10],[1,0,0,0,[27,[24,0],[\"remainingCharacters\"]]],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"action\",\"concat\",\"textarea\"]}",
    "meta": {
      "moduleName": "ln-ember-form-elements/templates/components/form-elements/expanding-textarea.hbs"
    }
  });
});