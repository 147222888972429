define("ember-collection/components/ember-collection/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template(
  /*
    <EmberNativeScrollable @content-size={{this._contentSize}} @scroll-left={{this._scrollLeft}} @scroll-top={{this._scrollTop}} @scrollChange={{this.scrollChange}} @clientSizeChange={{this.clientSizeChange}}>
    <div>
      {{~#each this._cells as |cell|~}}
        <div style={{{cell.style}}}>{{yield cell.item cell.index }}</div>
      {{~/each~}}
    </div>
  </EmberNativeScrollable>
  
  */
  {
    "id": "8r167PRN",
    "block": "{\"symbols\":[\"cell\",\"&default\"],\"statements\":[[7,\"ember-native-scrollable\",[],[[\"@content-size\",\"@scroll-left\",\"@scroll-top\",\"@scrollChange\",\"@clientSizeChange\"],[[27,[24,0],[\"_contentSize\"]],[27,[24,0],[\"_scrollLeft\"]],[27,[24,0],[\"_scrollTop\"]],[27,[24,0],[\"scrollChange\"]],[27,[24,0],[\"clientSizeChange\"]]]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"\\n  \"],[9,\"div\",true],[10],[5,[27,[26,1,\"BlockHead\"],[]],[[31,0,0,[27,[26,0,\"CallHead\"],[]],[[31,0,0,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[\"_cells\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[9,\"div\",true],[20,\"style\",[27,[24,1],[\"style\"]],null],[10],[16,2,[[27,[24,1],[\"item\"]],[27,[24,1],[\"index\"]]]],[11]],\"parameters\":[1]}]]],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "ember-collection/components/ember-collection/template.hbs"
    }
  });
});