define("ln-ember-contact-menu/templates/components/contact-menu-handle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "3ba72oad",
    "block": "{\"symbols\":[\"&attrs\",\"@class\",\"&default\",\"@userId\"],\"statements\":[[5,[27,[26,2,\"BlockHead\"],[]],[[27,[24,0],[\"isEnabled\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[1,1,0,0,\"  \"],[9,\"div\",false],[15,1],[14,\"id\",[27,[24,0],[\"_elementId\"]],null],[14,\"class\",[32,[[27,[24,2],[]],\" ContactMenuHandle\"]],null],[14,\"data-open\",[27,[24,0],[\"isOpen\"]],null],[23,\"tabindex\",\"0\",null],[14,\"onclick\",[31,177,2,[27,[26,1,\"CallHead\"],[]],[[27,[24,0],[\"openContactMenu\"]],[31,214,9,[27,[26,0,\"CallHead\"],[]],[\"contact-menu\"],[[\"userId\"],[[27,[24,4],[]]]]]],null],null],[10],[1,1,0,0,\"\\n    \"],[16,3,null],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[1,1,0,0,\"  \"],[9,\"div\",false],[15,1],[14,\"class\",[32,[[27,[24,2],[]],\" ContactMenuHandle\"]],null],[10],[1,1,0,0,\"\\n    \"],[16,3,null],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"component\",\"fn\",\"if\"]}",
    "meta": {
      "moduleName": "ln-ember-contact-menu/templates/components/contact-menu-handle.hbs"
    }
  });
});