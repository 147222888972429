define("ln-ember-form-elements/templates/components/form-elements/styled-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "spCZOAf/",
    "block": "{\"symbols\":[\"@confirm\",\"@confirmPlace\",\"&default\"],\"statements\":[[5,[27,[26,2,\"BlockHead\"],[]],[[27,[24,0],[\"loading\"]]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"  \"],[9,\"div\",true],[12,\"class\",\"loader\",null],[10],[1,1,0,0,\"\\n    \"],[9,\"svg\",true],[12,\"class\",\"circular\",null],[12,\"viewBox\",\"25 25 50 50\",null],[10],[1,1,0,0,\"\\n      \"],[9,\"circle\",true],[12,\"class\",\"path\",null],[12,\"cx\",\"50\",null],[12,\"cy\",\"50\",null],[12,\"r\",\"20\",null],[12,\"fill\",\"none\",null],[12,\"stroke-width\",\"4\",null],[12,\"stroke-miterlimit\",\"10\",null],[10],[11],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]],[1,1,0,0,\"\\n\"],[16,3,null],[1,1,0,0,\"\\n\\n\"],[5,[27,[26,2,\"BlockHead\"],[]],[[27,[24,0],[\"dialogVisible\"]]],null,[[\"default\"],[{\"statements\":[[5,[27,[26,1,\"BlockHead\"],[]],null,[[\"confirm\",\"cancel\",\"place\"],[[31,307,6,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[]],\"confirm\"],null],[31,337,6,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[]],\"cancel\"],null],[27,[24,2],[]]]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"    \"],[1,0,0,0,[27,[24,1],[]]],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"dialog-tooltip-on-parent\",\"if\"]}",
    "meta": {
      "moduleName": "ln-ember-form-elements/templates/components/form-elements/styled-button.hbs"
    }
  });
});