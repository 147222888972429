define("ln-ember-contact-menu/templates/components/fake-contact-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "IqbME6kO",
    "block": "{\"symbols\":[],\"statements\":[[9,\"ul\",true],[10],[1,1,0,0,\"\\n  \"],[1,0,0,0,[31,9,15,[27,[26,0,\"CallHead\"],[]],null,[[\"isFake\"],[true]]]],[1,1,0,0,\"\\n\\n  \"],[1,0,0,0,[31,51,15,[27,[26,0,\"CallHead\"],[]],null,[[\"isFake\"],[true]]]],[1,1,0,0,\"\\n\\n  \"],[9,\"ul\",true],[12,\"class\",\"micro-menu--group\",null],[10],[1,1,0,0,\"\\n    \"],[1,0,0,0,[31,128,15,[27,[26,0,\"CallHead\"],[]],null,[[\"isFake\",\"description\"],[true,true]]]],[1,1,0,0,\"\\n\\n    \"],[1,0,0,0,[31,199,15,[27,[26,0,\"CallHead\"],[]],null,[[\"isFake\",\"description\",\"hasIcon\"],[true,true,false]]]],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n\\n  \"],[1,0,0,0,[31,296,15,[27,[26,0,\"CallHead\"],[]],null,[[\"isFake\",\"description\"],[true,true]]]],[1,1,0,0,\"\\n\"],[11]],\"hasEval\":false,\"upvars\":[\"micro-menu-item\"]}",
    "meta": {
      "moduleName": "ln-ember-contact-menu/templates/components/fake-contact-menu.hbs"
    }
  });
});