define("ln-ember-form-elements/templates/components/form-elements/radio-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "msnLvyju",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[5,[27,[26,3,\"BlockHead\"],[]],[[31,0,0,[27,[26,2,\"CallHead\"],[]],[[31,0,0,[27,[26,2,\"CallHead\"],[]],[[27,[24,0],[\"formatedOptions\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"  \"],[1,0,0,0,[31,47,29,[27,[26,1,\"CallHead\"],[]],null,[[\"theme\",\"label\",\"value\",\"readonly\",\"onChange\"],[[27,[24,0],[\"theme\"]],[27,[24,1],[\"text\"]],[27,[24,1],[\"value\"]],[27,[24,1],[\"readonly\"]],[31,186,6,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[]],\"onOptionChange\",[27,[24,1],[\"id\"]]],null]]]]],[1,1,0,0,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"form-elements/styled-checkbox\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "ln-ember-form-elements/templates/components/form-elements/radio-group.hbs"
    }
  });
});