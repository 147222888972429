define("ln-ember-form-elements/templates/components/form-elements/-html-element", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "M/HJT3be",
    "block": "{\"symbols\":[\"@content\",\"&default\"],\"statements\":[[5,[27,[26,0,\"BlockHead\"],[]],[[28,[24,2]]],null,[[\"default\",\"else\"],[{\"statements\":[[1,1,0,0,\"  \"],[16,2,null],[1,1,0,0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[1,1,0,0,\"  \"],[1,0,0,0,[27,[24,1],[]]],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "ln-ember-form-elements/templates/components/form-elements/-html-element.hbs"
    }
  });
});