define("ln-ember-toolkit/templates/components/svg-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "9ekCDAJd",
    "block": "{\"symbols\":[\"&default\",\"@class\",\"@name\",\"@title\",\"@onClick\",\"&attrs\"],\"statements\":[[9,\"i\",false],[14,\"class\",[32,[\"SvgIcon SvgIcon--\",[27,[24,3],[]],\" \",[27,[24,2],[]]]],null],[14,\"title\",[27,[24,4],[]],null],[14,\"data-icon\",[27,[24,3],[]],null],[14,\"onclick\",[27,[24,5],[]],null],[15,6],[10],[1,1,0,0,\"\\n  \"],[1,0,0,0,[31,191,7,[27,[26,0,\"CallHead\"],[]],[[27,[24,3],[]]],null]],[1,1,0,0,\"\\n\"],[5,[27,[26,1,\"BlockHead\"],[]],[[28,[24,1]]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"    \"],[16,1,null],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"svg-jar\",\"if\"]}",
    "meta": {
      "moduleName": "ln-ember-toolkit/templates/components/svg-icon.hbs"
    }
  });
});