define("ln-ember-form-elements/templates/components/form-elements/calendar/navigation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "cvbtEEsi",
    "block": "{\"symbols\":[],\"statements\":[[9,\"a\",true],[12,\"class\",\"calendar-navigation__direction previous\",null],[13,\"onclick\",[31,111,6,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[]],\"onDirectionClick\",-1],null],null],[12,\"role\",\"button\",null],[10],[11],[1,1,0,0,\"\\n\"],[9,\"a\",true],[12,\"class\",\"calendar-navigation__label label\",null],[13,\"onclick\",[31,215,6,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[]],\"onClickMonth\"],null],null],[12,\"role\",\"button\",null],[10],[1,0,0,0,[27,[24,0],[\"label\"]]],[11],[1,1,0,0,\"\\n\"],[9,\"a\",true],[12,\"class\",\"calendar-navigation__direction next\",null],[13,\"onclick\",[31,329,6,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[]],\"onDirectionClick\",1],null],null],[12,\"role\",\"button\",null],[10],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "meta": {
      "moduleName": "ln-ember-form-elements/templates/components/form-elements/calendar/navigation.hbs"
    }
  });
});