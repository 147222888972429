define("ln-ember-form-elements/templates/components/form-elements/price", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "krzf8V3p",
    "block": "{\"symbols\":[\"scope\",\"NumberInput\",\"@appendElement\",\"@autocomplete\",\"@autoFocus\",\"@disabled\",\"@error\",\"@label\",\"@maxLength\",\"@name\",\"@placeholder\",\"@prependElement\",\"@readonly\",\"@value\",\"@onAccept\",\"@onFocusIn\",\"@onFocusOut\",\"@onKeyDown\",\"@onKeyPress\",\"@onKeyUp\",\"@min\",\"@max\",\"@scale\",\"@title\",\"@warning\",\"@class\",\"&attrs\"],\"statements\":[[5,[27,[26,1,\"BlockHead\"],[]],[\"FormPrice\",[31,22,9,[27,[26,0,\"CallHead\"],[]],[\"form-elements/number\"],null]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"  \"],[7,[27,[24,2],[]],[[14,\"class\",[32,[[27,[24,1],[]],\" \",[27,[24,26],[]]]],null],[15,27]],[[\"@appendElement\",\"@autocomplete\",\"@autofocus\",\"@disabled\",\"@error\",\"@label\",\"@maxlength\",\"@name\",\"@placeholder\",\"@prependElement\",\"@readonly\",\"@value\",\"@unit\",\"@unitSeparator\",\"@placeUnitBefore\",\"@onAccept\",\"@onFocusIn\",\"@onFocusOut\",\"@onKeyDown\",\"@onKeyPress\",\"@onKeyUp\",\"@min\",\"@max\",\"@padFractionalZeros\",\"@normalizeZeros\",\"@scale\",\"@title\",\"@warning\"],[[27,[24,3],[]],[27,[24,4],[]],[27,[24,5],[]],[27,[24,6],[]],[27,[24,7],[]],[27,[24,8],[]],[27,[24,9],[]],[27,[24,10],[]],[27,[24,11],[]],[27,[24,12],[]],[27,[24,13],[]],[27,[24,14],[]],[27,[24,0],[\"formatSettings\",\"currencySign\"]],\" \",[27,[24,0],[\"formatSettings\",\"isSignBefore\"]],[27,[24,15],[]],[27,[24,16],[]],[27,[24,17],[]],[27,[24,18],[]],[27,[24,19],[]],[27,[24,20],[]],[27,[24,21],[]],[27,[24,22],[]],[27,[24,0],[\"_padFractionalZeros\"]],false,[27,[24,23],[]],[27,[24,24],[]],[27,[24,25],[]]]],null],[1,1,0,0,\"\\n\"]],\"parameters\":[1,2]}]]]],\"hasEval\":false,\"upvars\":[\"component\",\"let\"]}",
    "meta": {
      "moduleName": "ln-ember-form-elements/templates/components/form-elements/price.hbs"
    }
  });
});